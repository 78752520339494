import * as React from "react"
import Layout from "../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/hero-section"

import IceBerg from "../images/quickcheck/iceBerg.jpg"
import QuickcheckForm from "../components/quickcheck/quickCheckForm"
import {
  BGLinearGradient,
  Separator,
} from "../components/common/styledComponentsHelper"

import {
  whyQuickcheckText,
  quickcheckText,
  lowerPriceLimitText,
  formCaptionText,
} from "../static/text/quickcheckContent.jsx"
import AppointletButton from "./../components/common/appointletButton"

export const Head = () => (
  <>
    <title>Quick Check | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const QuickCheck = () => (
  <Layout>
    <HeroSection
      desktopImg="quickcheck_banner_desktop.png"
      mobileImg="quickcheck_banner_mobile.png"
      headlineText="Quick Check"
      responsiveHeadline="Quick Check"
      subheadlineText="Unsere geschulten Augen erkennen Ihr Potenzial"
      captionText=""
    />
    <BGLinearGradient>
      <Separator />
      <div className="container">
        <div className="row flex-lg-row-reverse">
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-start">
                    <h2 className="text-uppercase text-light fw-bold">
                      Warum der{" "}
                      <span className="text-success">Quick Check?</span>
                    </h2>
                    <div className="text-light mt-4 fs-5">
                      {whyQuickcheckText}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <div className="d-flex justify-content-end">
              <StaticImage
                src="../images/quickcheck/quick_check.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Quick-Check"
                placeholder="none"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <Separator />
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-end">
                    <h2 className="text-uppercase fw-bold text-light">
                      Kennen Sie Ihre{" "}
                      <span className="text-success">Preisuntergrenze?</span>
                    </h2>
                    <div className="text-light mt-4 fs-5 ps-5">
                      {lowerPriceLimitText}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3 d-flex justify-content-center align-items-center">
            <StaticImage
              src="../images/quickcheck/rendite_kurs.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Preisuntergrenze"
              placeholder="none"
              width={350}
              height={350}
            />
          </div>
        </div>
      </div>
      <Separator />
      <Parallax>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 my-5 d-block">
              <table className="h-100" style={{ zIndex: 1000 }}>
                <tbody>
                  <tr>
                    <td className="ps-sm-5 ps-3 pe-sm-5 pe-3 align-middle">
                      <h2 className="text-light text-uppercase fw-bold">
                        Wir steigen für Sie tief in <br />
                        <span className="text-success">Ihre Zahlen ein</span>
                      </h2>
                      <div className="text-light mt-4 fs-5">
                        {quickcheckText}
                      </div>
                      <br />
                      <AppointletButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Parallax>
      <Separator />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <h2 className="text-uppercase text-center text-light fw-bold">
              Ihre finanziellen Vorteile mit der{" "}
              <span className="text-success">Bierdeckelkalkulation</span>
            </h2>
            <div className="text-light text-center mt-4 fs-5">
              {formCaptionText}
            </div>
          </div>
        </div>
        <QuickcheckForm />
      </div>
      <Separator />
    </BGLinearGradient>
  </Layout>
)

const Parallax = styled.div`
  background-image: url(${IceBerg});
  min-height: 800px;
  height: 800px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`

export default QuickCheck
