import React from "react"

export const whyQuickcheckText = (
  <React.Fragment>
    Der Quick Check ist der erste Schritt zu mehr Gewinn- Entdecken Sie, was in
    Ihnen und Ihrem Handwerksbetrieb steckt!
  </React.Fragment>
)

export const lowerPriceLimitText = (
  <React.Fragment>
    Mit Ihrer richtigen Preisuntergrenze erkennen Sie ab sofort ob Sie mit dem
    Auftrag Geld verdienen oder verlieren. <br />
    Wir sind die Speziallisten im Ermitteln Ihrer persönlichen Preisuntergrenze,
    so wie Sie Spezialist in Ihrem Handwerk sind!
  </React.Fragment>
)

export const quickcheckText = (
  <React.Fragment>
    Weil zu einer ordentlichen Planung jede Menge Wissen notwendig ist,
    unterstützen wir Sie mit unserer langjährigen Erfahrung und steigen hierzu
    tief in Ihre Zahlen ein.
  </React.Fragment>
)

export const formCaptionText = (
  <React.Fragment>
    <p>
      Der Bierdeckel deckt die versteckten Einsparungspotentiale in Ihrem
      Handwerksbetrieb auf und zeigt Ihnen die Stellschrauben, um Ihren Betrieb
      noch produktiver zu machen!
    </p>
    <p>
      Einfach die 4 Kennzahlen ausfüllen und Sie erhalten eine individuelle
      Einschätzung Ihrer finanziellen Möglichkeiten!
    </p>
    <p>
      Gerne besprechen wir die Ergebnisse unverbindlich und kostenfrei für Sie
      am Telefon.
    </p>
  </React.Fragment>
)
