import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import TextError from "../common/textError"
import styled from "styled-components"
import axios from "axios"
import { Link } from "gatsby"
import { successColor } from "./../../static/styles/colors"

const initialValues = {
  Jahresumsatz: "",
  Stundenverrechnungssatz: "",
  MaterialeinsatzQuote: "",
  AufschlagAufMaterialeinsatz: "",
  Vorname: "",
  Nachname: "",
  Email: "",
  Telefonnummer: "",
  Betrieb: "",
  Datenschutz: false,
  HoneyPot: "",
  dataSendFrom: "quickCheckSite",
}

const validationSchema = Yup.object({
  Jahresumsatz: Yup.number()
    .typeError("Jahresumsatz muss eine Zahl sein!")
    .min(0, "Jahresumsatz muss mindestens 0 sein!")
    .required("Jahresumsatz muss ausgefüllt sein!"),
  Stundenverrechnungssatz: Yup.number()
    .typeError("Stundenverrechnungssatz muss eine Zahl sein!")
    .min(0, "Stundenverrechnungssatz muss mindestens 0 sein!")
    .required("Stundenverrechnungssatz muss ausgefüllt sein!"),
  MaterialeinsatzQuote: Yup.number()
    .typeError("Materialeinsatz muss eine Zahl sein!")
    .min(0, "Materialeinsatz-Quote muss mindestens 0 sein!")
    .required("Materialeinsatz-Quote muss ausgefüllt sein!"),
  AufschlagAufMaterialeinsatz: Yup.number()
    .typeError("Aufschlag auf den Materialeinsatz muss eine Zahl sein!")
    .min(0, "Aufschlag auf den Materialeinsatz muss mindestens 0 sein!")
    .required("Aufschlag auf den Materialeinsatz muss ausgefüllt sein!"),
  Vorname: Yup.string().required("Vorname muss ausgefüllt sein!"),
  Nachname: Yup.string().required("Nachname muss ausgefüllt sein!"),
  Email: Yup.string()
    .email("E-Mail-Adresse ist nicht valide")
    .required("E-Mail-Adresse muss ausgefüllt sein!"),
  Betrieb: Yup.string().required("Betrieb-Name muss ausgefüllt sein!"),
  Datenschutz: Yup.boolean().oneOf(
    [true],
    "Bitte die Datenschutzbestimmungen akzeptieren!"
  ),
})

const QuickcheckForm = () => {
  const [serverState, setServerState] = useState()
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onSubmit = (values, submitProps) => {
    axios
      .post("https://fachhandwerk360.de/webmailer/kontakt.php", values)
      .then(response => {
        submitProps.setSubmitting(false)
        submitProps.resetForm()
        handleServerResponse(
          true,
          "Vielen Dank für Ihre Zusendung. Wir werden uns so schnell wie möglichen bei Ihnen melden."
        )
      })
      .catch(error => {
        submitProps.setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formik => {
        return (
          <div className="container p-sm-5 p-3">
            <Form>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <Field
                      type="text"
                      id="HoneyPot"
                      name="HoneyPot"
                      className="form-control border-success border-3 d-none"
                    />
                    <label
                      htmlFor="Jahresumsatz"
                      className="text-light d-sm-none d-block"
                    >
                      Ihr Jahresumsatz in €
                    </label>
                    <Field
                      type="number"
                      id="Jahresumsatz"
                      name="Jahresumsatz"
                      placeholder="Ihr Jahresumsatz in €"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage name="Jahresumsatz" component={TextError} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="Stundenverrechnungssatz"
                      className="text-light d-sm-none d-block"
                    >
                      Ihr Durchschnittlicher Stundenverrechnungssatz in €
                    </label>
                    <Field
                      type="number"
                      id="Stundenverrechnungssatz"
                      name="Stundenverrechnungssatz"
                      placeholder="Ihr Durchschnittlicher Stundenverrechnungssatz in €"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage
                      name="Stundenverrechnungssatz"
                      component={TextError}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="MaterialeinsatzQuote"
                      className="text-light d-sm-none d-block"
                    >
                      Ihre Materialeinsatz-Quote in %
                    </label>
                    <Field
                      type="number"
                      id="MaterialeinsatzQuote"
                      name="MaterialeinsatzQuote"
                      placeholder="Ihre Materialeinsatz-Quote in %"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage
                      name="MaterialeinsatzQuote"
                      component={TextError}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="AufschlagAufMaterialeinsatz"
                      className="text-light d-sm-none d-block"
                    >
                      Ihr Aufschlag auf den Materialeinsatz in %
                    </label>
                    <Field
                      type="number"
                      id="AufschlagAufMaterialeinsatz"
                      name="AufschlagAufMaterialeinsatz"
                      placeholder="Ihr Aufschlag auf den Materialeinsatz in %"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage
                      name="AufschlagAufMaterialeinsatz"
                      component={TextError}
                    />
                  </div>
                </div>
              </div>
              <Separator />
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="Vorname"
                      className="text-light d-sm-none d-block"
                    >
                      Ihr Vorname
                    </label>
                    <Field
                      type="text"
                      id="Vorname"
                      name="Vorname"
                      placeholder="Ihr Vorname"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage name="Vorname" component={TextError} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="Nachname"
                      className="text-light d-sm-none d-block"
                    >
                      Ihr Nachname
                    </label>
                    <Field
                      type="text"
                      id="Nachname"
                      name="Nachname"
                      placeholder="Ihr Nachname"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage name="Nachname" component={TextError} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="Email"
                      className="text-light d-sm-none d-block"
                    >
                      Ihre E-Mail-Adresse
                    </label>
                    <Field
                      type="email"
                      id="Email"
                      name="Email"
                      placeholder="Ihre E-Mail-Adresse"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage name="Email" component={TextError} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mt-3">
                    <label
                      htmlFor="Telefonnummer"
                      className="text-light d-sm-none d-block"
                    >
                      Ihre Telefonnummer
                    </label>
                    <Field
                      type="text"
                      id="Telefonnummer"
                      name="Telefonnummer"
                      placeholder="Ihre Telefonnummer"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage name="Telefonnummer" component={TextError} />
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <label
                  htmlFor="Betrieb"
                  className="text-light d-sm-none d-block"
                >
                  Ihr Betrieb-Name
                </label>
                <Field
                  type="text"
                  id="Betrieb"
                  name="Betrieb"
                  placeholder="Ihr Betrieb-Name"
                  className="form-control border-success border-3"
                />
                <ErrorMessage name="Betrieb" component={TextError} />
              </div>
              <div className="form-group mt-3">
                <label
                  htmlFor="Datenschutz"
                  className="text-gray-500 font-bold"
                >
                  <Field
                    name="Datenschutz"
                    id="Datenschutz"
                    className="me-2 leading-tight"
                    type="checkbox"
                  />
                  <span className="text-light">
                    Ich habe die{" "}
                    <Link
                      to="/datenschutzerklaerung"
                      style={{ color: successColor }}
                    >
                      Datenschutzrichtlinien
                    </Link>{" "}
                    gelesen und akzeptiere diese.
                  </span>
                </label>
                <br />
                <ErrorMessage name="Datenschutz" component={TextError} />
              </div>
              <button
                className="shadow-inset-success-sm pt-2 pb-2 rounded-0 mt-3 d-block btn btn-secondary text-uppercase text-success border-success"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Anfrage Senden
              </button>
              {serverState && (
                <div className="fs-5 text-light mt-3">{serverState.msg}</div>
              )}
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

const Separator = styled.hr`
  border: 5px solid transparent;
  padding: 1% 0px 1% 0px;
  margin: 0px;
  opacity: 0;
`

export default QuickcheckForm
